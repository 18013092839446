@font-face {
  font-family: 'Pixel';
  src: local('Pixel'),url(./FreePixel.ttf) format('truetype');
}

html,
body {
  min-height: 100%;
  font-family: Pixel;
  background-color: #f5f5f5;
  height: 100vh !important;

  /* background-color: rgb(15, 23, 42); */
  /* color: white; */
}

.bg-image{
  /* background-image: url(); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height:100vh;  /* responsive height */
}

.dark {
  background-color: rgb(15, 23, 42);
  color: white;
  min-height: 100vh;
}

.dark-black {
  background-color: black;
  color: white;
  min-height: 100vh;
}

.dark-color {
  background-color: rgb(15, 23, 42);
  color: white;
  border-color: white;
}


.dark-color .card {
  background-color: rgb(15, 23, 42);
  color: white;
}

.h-100vh {
  min-height: 100vh !important;
}

.body-wrapper {
  padding-top: 20px;
  background-color: #f5f5f5;
}

.top-index {
  z-index: 5;
}

.balance-text {
  text-align: center;
}

@media (min-width: 992px) {
  .body-wrapper {
    display: flex;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .logo {
  }
  .form-signin {
    padding: 15px;
  }
  .play {
    width: 80vw;
    padding: 15px;
  }
  .balance-text {
    text-align: right;
  }
  
}

.form-signin {
  width: 100%;
  max-width: 420px;
  margin: auto;
}

.play {
  width: 90vw;
  max-width: 1000px;
  margin: auto;
  z-index: 5;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.logo {
  height: 200px;
  width: 200px;
}


.faq {
  width: 69vw;
  max-width: 1000px;
  padding: 15px;
  margin: auto;
}

.coin-flipping {
  width: 100%;
}

.coin-flipping-square {
  width: 200px;
  height: 200px;
}

@media only screen and (max-width: 600px) {
  .logo {
    height: 128px;
    width: 128px;
  }
  .faq {
    padding: 0;
    width: 90vw;
  }
}

.accordion-button:focus {
  border-color: transparent;
}

.accordion-button:not(.collapsed) {
  color: black;
  background-color: #D3D3D3;
}
.accordion-button:after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23a3acb9'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.cursor-pointer {
  cursor:pointer;
}

.double-button:hover {
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

.selected {
  border: solid 2px black;
}

.dark .selected {
  border: solid 3px white;
}

.dark-black .selected {
  border: solid 4px #ffdf58;
}

p {
  white-space: pre-wrap;
}

.text-twitter{
  color:#1DA1F2 !important;
}

.text-discord{
  color:#5865f2 !important;
}


.social-icons {
  position: relative;
  z-index: 2;
}
/* .social-icons .btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  height: 4rem;
  width: 4rem;
  border-radius: 100rem;
} */

@media (min-width: 992px) {
  .social-icons {
    position: absolute;
    height: 100%;
    top: 0;
    right: 1.8rem;
    width: auto;
  }
  .social-icons-left {
    position: absolute;
    height: 100%;
    top: 0;
    left: 1.8rem;
    width: auto;
  }
  .social-icons-bottom-left {
    position: absolute;
    /* height: 100%; */
    bottom: 1.8rem;
    left: 1.8rem;
    width: auto;
    z-index: 10;
  }
  .social-icons-bottom-right {
    position: absolute;
    /* height: 100%; */
    bottom: 1.8rem;
    right: 1.8rem;
    width: auto;
    z-index: 10;
  }
}

.toast-header {
  color: black !important;
  background-color: #ffdf58 !important;
}

.toast-body {
  text-align: left !important;
}

.dark .toast-body {
  background-color: rgb(15, 23, 42);
  color: white;
  border: solid 1px white;
  border-top: none;
}

.dark-black .toast-body {
  background-color: black;
  color: white;
  border: solid 1px white;
  border-top: none;
}

.sol-balance {
  animation: pop 0.3s linear 1;
}

@keyframes pop{
  50%  {transform: scale(1.2);}
}

.pop {
  animation: pop 0.3s linear 1;
}


.leaderboard-list {
  width: 469px;
}

.form-signin2 {
  width: 100%;
  max-width: 600px;
  padding: 15px;
  margin: auto;
}

.shortcut-row {

}
.profile-picture {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}


.profile-picture-md .image {
  height: 2.4em;
  width: 2.4em;
}

.time-in-row {
  position: absolute;
  right: 10px;
  bottom: 0;
}

.profile-picture .image {
  width: 2rem!important;
  height: 2rem!important;
}

@media (max-width: 500px) {
  .shortcut-row {
    width: 100%;
    padding-left: 8px;
    padding-right: 8px;
  }

  .shortcut-row .btn {
    width: 100%;
  }

  .shortcut-row .leaderboard-list {
    width: 100%;
  }
  .toolbar {
    justify-content: center !important;
  }
}

.toolbar {
  justify-content: flex-end;
}

.profile-picture .image-large {
  width: 128px;
  height: 128px;
}

.dark-black .list-group-item:hover {
  background-color: rgba(0, 0, 0, 0.5) !important;
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

.dark .list-group-item:hover {
  background-color: rgba(108, 122, 137, 0.95) !important;
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

.light .list-group-item:hover {
  background-color: rgba(236, 236, 236, 0.95) !important;
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

.list-group-item a {
  text-decoration: none;
  color: black;
}

/* dark black */

.dark-black .body-wrapper {
  background-color: black;
}

.dark-black .list-group-item {
  background-color: black;
  color: white;
}


.dark-black .btn-dark {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.dark-black .btn-outline-dark {
  color: #f8f9fa;
  border-color: #f8f9fa;
}




/* end dark black */

.dark .body-wrapper {
  background-color: rgb(15, 23, 42);
}

.dark .list-group-item {
  background-color: rgb(30, 41, 59);
  color: white;
}


.dark .btn-dark {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.dark .btn-outline-dark {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

body.dark .modal-content {
	background-color: #1e2229;
}

body.dark .modal-content .modal-header,
body.dark .modal-content .modal-footer {
	border-color: #424242;
}

body.dark .form-control {
	background-color: #282d36;
	border-color: #282d36;
}

.dark-color .form-control {
  color: white;
  border: solid 1px white;
  background-color: rgb(30, 41, 59);
}

body.dark .card {
  background-color: rgb(15, 23, 42);
  color: white;
  border: solid 1px white;
}

body.dark .form-control {
  color: white;
  border: solid 1px white;
  background-color: rgb(30, 41, 59);
}

body.dark .double-button:hover {
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(255, 255, 255, 0.1), 0 3px 6px rgba(255, 255, 255, 0.08);
}


body.dark .selected {
  border: solid 3px white;
}

body.dark .btn-warning {
  color: black !important;
  background-color: #ffdf58 !important;
}

.imageWrapper {
  position: relative;
  /* width: 300px; */
  /* height: 300px; */
}
.imageWrapper img {
  display: block;
}
.imageWrapper .cornerLink {
  opacity: 0;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding: 2px 0px;
  color: #ffffff;
  background: #000000;
  text-decoration: none;
  text-align: center;
  -webkit-transition: opacity 500ms;
  -moz-transition: opacity 500ms;
  -o-transition: opacity 500ms;
  transition: opacity 500ms;

}
.imageWrapper:hover .cornerLink {
  opacity: 0.8;
}

.dark a {
  color: white;
}

.dark-black a {
  color: white;
}

.shake-it {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.5s;

  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

.progress {
  height: 0.5rem;
  background-color: transparent;
}

video.bg-video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translateX(-50%) translateY(-50%);
  z-index: 0;
}

.z-index-10 {
  z-index: 10;
}


.masthead {
  position: relative;
  overflow: hidden;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.masthead:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.masthead .masthead-content {
  position: relative;
  max-width: 40rem;
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.masthead .masthead-content h1, .masthead .masthead-content .h1 {
  font-size: 2.5rem;
}
.masthead .masthead-content p {
  font-size: 1.2rem;
}
.masthead .masthead-content p strong {
  font-weight: 700;
}
.masthead .masthead-content .input-group-newsletter input {
  height: auto;
  width: 100%;
  font-size: 1rem;
  padding: 1rem;
}
.masthead .masthead-content .input-group-newsletter button {
  font-size: 0.85rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: calc(1rem + 2px);
}

@media (min-width: 992px) {
  .masthead {
    height: 100%;
    width: 100%;
    min-height: 0;
    padding-bottom: 0;
  }
  .masthead:before {
    /* transform: skewX(-9deg);
    transform-origin: top right; */
  }
  .masthead .masthead-content {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .masthead .masthead-content h1, .masthead .masthead-content .h1 {
    font-size: 3.5rem;
  }
  .masthead .masthead-content p {
    font-size: 1.3rem;
  }
}
@media (min-width: 1200px) {
  .masthead {
    width: 100%;
  }
}

.players-card-header {
  background-color: rgba(0, 0, 0, 0.2);
}
.no-decoration {
  text-decoration: none;
  color:inherit;
}

#dropdown-button-dark-example1 {
  margin-left: 0.5rem;
  height: 100%;
  display: flex;
}


#dropdown-button-dark-example1::after {
  display: none !important;
}

.whale-text {
  text-decoration: underline;
  font-size: 17px;
}